import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

function PlaceLink({ place }) {
  const url = new URL(place.url);

  return (
    <OutboundLink
      target="_blank"
      rel="noopener noreferrer"
      className="placeLink"
      href={place.url}
    >
      {url.host}
      {url.pathname.replace(/\/$/, "")}
    </OutboundLink>
  );
}

export default PlaceLink;
