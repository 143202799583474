import styled, { css } from "styled-components";

// Component Styles
export const Tags = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  font-family: monospace;

  @media all and (max-width: 800px) {
    label {
      flex: 1 0 auto;
    }
  }
`;

export const Tag = styled.label`
  background: white;
  margin: 2px;
  border-radius: 3px;
  padding: 3px 5px;
  color: var(--bg);
  transition: background-color 0.2s;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  font-size: var(--typeScaleSmall);

  ${props =>
    props.interactive &&
    !props.textOnly &&
    !props.currentTag &&
    !props.noResults &&
    css`
      &:hover,
      &:focus {
        background: #cccccc;
      }
    `}


  input {
    display: none;
  }

  ${props =>
    props.textOnly &&
    !props.currentTag &&
    css`
      background: none;
      color: var(--textNormal);
    `}

  ${props =>
    props.currentTag &&
    css`
      background: var(--hamiltonBlue);
      color: var(--textNormal);
    `}

  ${props =>
    props.noResults &&
    !props.currentTag &&
    css`
      background: #363c48;
      color: var(--textNormal);
      opacity: 0.6;
    `}
`;
