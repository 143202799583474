import React from "react";

import SimpleOpeningHours from "../util/simple-opening-hours";
import { timeConvert } from "../util/time";

const dayStrings = {
  su: "Sunday",
  mo: "Monday",
  tu: "Tuesday",
  we: "Wednesday",
  th: "Thursday",
  fr: "Friday",
  sa: "Saturday",
  ph: "Holidays",
};

function formatHourText(hoursArray) {
  if (hoursArray.length === 0) {
    return "Closed";
  }

  const formattedArray = hoursArray.map(hourRange =>
    hourRange
      .split("-")
      .map(timeConvert)
      .join("-")
  );

  return formattedArray.join(", ");
}

function Hours({ place }) {
  if (!place.hours) {
    return <p>Information on open hours is not yet available.</p>;
  }
  const openingHours = new SimpleOpeningHours(place.hours);
  const openingHoursTable = openingHours.getTable();

  const openingHoursKeys = Object.keys(openingHoursTable);
  const openingHoursValues = Object.values(openingHoursTable);

  return (
    <table className="hoursTable">
      <caption>Location Hours</caption>
      <tbody>
        {openingHoursValues.map((hoursArray, i) => {
          const dayString = dayStrings[openingHoursKeys[i]];

          return (
            <tr key={dayString}>
              <th>{dayString}</th>
              <td>
                {hoursArray.length === 0
                  ? "Closed"
                  : formatHourText(hoursArray)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Hours;
