import { graphql } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import React from "react";
import { css } from "styled-components";

import Layout from "../components/layout";
import Hours from "../components/Hours";
import PlaceLink from "../components/PlaceLink";
import SEO from "../components/seo";
import SocialShare from "../components/SocialShare";
import { Tag, Tags } from "../components/Tags";

import twitter from "../images/twitter.png";
import facebook from "../images/facebook.png";
import instagram from "../images/instagram.png";

import doordash from "../images/doordash.png";
import ubereats from "../images/ubereats.png";
import skipthedishes from "../images/skipthedishes.png";

const pageStyles = css`
  max-width: 80ch;

  header {
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .socialMediaList,
  .deliveryServicesList {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;

    margin: 20px 0;
    padding: 0;

    li {
      margin-right: 10px;
    }

    img {
      height: 40px;
    }
  }

  .location_detailsSection {
    display: flex;
    flex-wrap: wrap;
  }

  .location_info {
    margin-right: 40px;
  }

  h1 {
    margin-bottom: 0px;
  }

  .placeLink {
    white-space: nowrap;
    text-decoration: none;
    font-family: monospace;
    letter-spacing: 1px;
    font-size: var(--typeScaleSmall);
  }

  .hoursTable {
    margin-bottom: 20px;
  }

  table {
    line-height: 1.25;
  }

  caption {
    padding: 3px 5px;
    font-weight: bold;
  }

  th,
  td {
    padding: 3px 5px;
    text-align: left;
  }

  [class^="Tags__Tag"] {
    cursor: default;
  }
`;

export const query = graphql`
  query($slug: String!) {
    place(slug: { eq: $slug }) {
      ...placeFragment
    }
  }
`;

function Location(props) {
  const place = props.data.place;

  const meta = [
    {
      property: `keywords`,
      content: place.tags.join(", "),
    },
  ];

  return (
    <Layout>
      <SEO title={place.name} description={place.description} meta={meta} />
      <div css={pageStyles}>
        <section>
          <header>
            <h1>{place.name}</h1>
            <PlaceLink place={place}></PlaceLink>
          </header>

          <SocialShare place={place}></SocialShare>

          <p>{place.description}</p>

          {place.takeoutText && <p>{place.takeoutText}</p>}
          {place.deliveryText && <p>{place.deliveryText}</p>}

          <Tags>
            {place.tags.map(tag => (
              <Tag key={tag} as="li" currentTag={false} small>
                {tag}
              </Tag>
            ))}
          </Tags>
        </section>
        <section className="location_detailsSection">
          <div className="location_info">
            <address>
              <span>
                {place.address}{" "}
                <OutboundLink
                  href={
                    "https://www.google.com/maps/search/?api=1&query=" +
                    encodeURI(place.name)
                  }
                >
                  (map)
                </OutboundLink>
              </span>
              <br />
              <OutboundLink href={"mailto:" + place.email}>
                {place.email}
              </OutboundLink>
              <br />
              <OutboundLink href={"tel:1+" + place.phone}>
                {place.phone}
              </OutboundLink>
            </address>

            <ul className="deliveryServicesList">
              {place.SkipTheDishes && (
                <li>
                  <OutboundLink
                    aria-label="Order on Skip The Dishes"
                    href={place.SkipTheDishes}
                  >
                    <img src={skipthedishes} alt="" />
                  </OutboundLink>
                </li>
              )}
              {place.DoorDash && (
                <li>
                  <OutboundLink
                    aria-label="Order on Door Dash"
                    href={place.DoorDash}
                  >
                    <img src={doordash} alt="" />
                  </OutboundLink>
                </li>
              )}
              {place.UberEats && (
                <li>
                  <OutboundLink
                    aria-label="Order on UberEats"
                    href={place.UberEats}
                  >
                    <img src={ubereats} alt="" />
                  </OutboundLink>
                </li>
              )}
            </ul>
            <ul className="socialMediaList">
              {place.twitter && (
                <li>
                  <OutboundLink
                    aria-label="View twitter profile"
                    href={place.twitter}
                  >
                    <img src={twitter} alt="" />
                  </OutboundLink>
                </li>
              )}
              {place.facebook && (
                <li>
                  <OutboundLink
                    aria-label="View facebook profile"
                    href={place.facebook}
                  >
                    <img src={facebook} alt="" />
                  </OutboundLink>
                </li>
              )}
              {place.instagram && (
                <li>
                  <OutboundLink
                    aria-label="View instagram profile"
                    href={place.instagram}
                  >
                    <img src={instagram} alt="" />
                  </OutboundLink>
                </li>
              )}
            </ul>
          </div>
          <Hours place={place}></Hours>
        </section>
      </div>
    </Layout>
  );
}

export default Location;
