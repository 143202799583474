import React from "react";
import styled from "styled-components";

import { FacebookShareButton, TwitterShareButton } from "react-share";

// import facebook from "../images/facebook.png";
// import twitter from "../images/twitter.png";

const Container = styled.div`
  button {
    margin-right: 10px;
  }
`;

const ShareButtonContent = styled.div`
  display: flex;
  align-items: center;
  font-size: var(--typeScaleSmall);

  padding: 2px 5px 2px 5px;
  border-radius: 5px;

  font-weight: bold;

  &.facebook {
    background: #1778f2;
    border: 1px solid #569ef5;
  }

  &.twitter {
    background: #1da1f2;
    border: 1px solid #56b8f5;
  }

  img {
    height: 25px;
    margin-right: 5px;
  }
`;

function SocialShare({ config }) {
  if (typeof window === `undefined`) {
    //TODO - We could SSR this, need to pass through or create canonical url for links
    return null;
  }

  return (
    <Container>
      <FacebookShareButton
        url={window.location.href}
        aria-label="Share on Facebook"
      >
        <ShareButtonContent className="facebook">
          {/* <img src={facebook} alt="" /> */}
          <span>Share</span>
        </ShareButtonContent>
      </FacebookShareButton>

      <TwitterShareButton
        url={window.location.href}
        aria-label="Share on Twitter"
      >
        <ShareButtonContent className="twitter">
          {/* <img src={twitter} alt="" /> */}
          <span>Tweet</span>
        </ShareButtonContent>
      </TwitterShareButton>
    </Container>
  );
}

export default SocialShare;
